.btn {

  &-custom {
    position: relative;
    background: none;
    overflow: hidden;
    z-index: 10;
    border-radius: $border-radius;
    color: $gray-900 !important;

    &:after {
      position: absolute;
      background: white;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      content: '';
      z-index: 1;
      border-radius: $border-radius;
    }

    &:before {
      border-radius: $border-radius;
      content: '';
      position: absolute;
      z-index: -1;
      top: -250%;
      width: 200%;
      right: 0;
      bottom: 0;
      left: -50%;
      margin: -1px;
      transition: ease-out 1s;
      height: 500%;
      background: linear-gradient(to left, #FFF700, #FFF700, #FFF700, #F2EA00, #D90018, #D90018, #33358B, #33358B, #33358B, #33358B);
    }

  }

  &-content {
    position: relative;
    z-index: 4;

    &--sec {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &:hover {
    &:before {
      transition: ease-out 1s;
      transform: rotate(-330deg);
    }
  }

  i {
    transition: ease-out 1s;
    margin-left: 10px;
  }

  &:hover:not(.no-iteraction) {
    i {
      transition: linear 0.3s;
      margin-left: 5px;
      margin-right: 5px;
      //opacity: 0.8;
    }
  }

  &:focus {
    .btn-content--sec {
      transition: ease-out 0.3s;
      transform: scale(3);
      opacity: 0;
    }
  }
}

.neos-backend {
  .btn-content {
    position: static;
  }
}

.app-site-buttoncontainer {
  .btn {
    display: block;
    margin: 10px;
    @include media-breakpoint-up(md) {
      display: inline-block;
      margin: 0;
      margin-right: 4px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
