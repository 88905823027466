.cache {
  min-height: 400px;
  overflow: hidden;
  margin-bottom: $grid-gutter-width;

  &.col-md-6 {
    min-height: 650px;

    .cache__front, .cache__back {
      @include media-breakpoint-up(md) {
        padding: 50px;
      }
    }
  }

  &__front, &__back {
    padding: 16px;
    background-size: cover;
    transition: ease-in 1s;
    display: flex;
    justify-content: center;
    position: relative;

    .neos-nodetypes-text {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    div {
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      background: rgba(black, 0.2);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }

    & > div {
      align-self: center;
    }
  }

  &__back {
    text-align: center;
    overflow: hidden;

    & > div {
      align-self: flex-end;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &:before {
      background: rgba(#33358B, 0.55);
      z-index: 2;
    }

    img {
      top: 0;
      filter: grayscale(100%);
      z-index: 1;
      bottom: 0;
      width: 100%;
      height: auto;
      transition: ease-in 1s;
    }
  }

  &:hover {
    .cache__front {
      margin-top: -400px;
      transition: ease-out 0.2s;
    }

    &.col-md-6 {
      .cache__front {
        margin-top: -650px;
      }
    }

    .cache__back {
      img {
        transition: linear 60s;
        transform: scale(3);

      }
    }
  }
}

.neos-backend {
  //.cache {
  //  overflow: visible;
  //  max-height: none;
  //  &__front {
  //    margin-top: 0;
  //  }
  //}
}
