.neos-nodetypes-image {
  img {
    width: 100%;
    height: auto;
    //margin-top: 50px;
    //margin-bottom: 50px;
    @extend .rounded;
  }
}

.user-content {
  font-size: 20px;
  font-weight: 300;
}

.body {
  &-top-padding {
    @include media-breakpoint-up(xl) {
      @media screen {
        padding-top: 200px;
      }
    }
  }
}

.no-margin-img {
  img {
    margin: 0;
  }
}

.opacity-0 {
  opacity: 0;
}

.bg-lightdark {
  background: rgba($gray-200, 1) !important;
}

@media print {
  .bg-lightdark {
    background: rgba($gray-200, 1) !important;
  }
}

.bg-fixed {
  background-attachment: fixed;
}

.neos-nodetypes-text, .neos-nodetypes-textwithimage {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 2rem;

  span {
    display: block;
  }

  .table {
    @extend .table-striped;

    td {
      border: none;
    }
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &:before {
        position: absolute;
        width: 12px;
        height: 12px;
        //background: $dark;
        content: '';
        left: -30px;
        //height: 2px;
        top: 18px;
        background: none !important;
        border-right: 4px solid gray;
        border-top: 4px solid gray;
        transform: rotate(45deg);
        border-radius: 10%;
      }
    }
  }
}

.app-site-backgroundimage {
  background-size: cover;

  &.repeat-x {
    background-repeat: repeat-x;
    background-size: auto;
  }

  &.repeat-y {
    background-repeat: repeat-y;
    background-size: auto;
  }

  &.text-light {
    .bar {
      background: white !important;

      &__content {
        background: white !important;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0 !important;
  }

  ul {
    li {
      &:before {
        border-color: white !important;
        top: 14px;
      }
    }
  }
}


.clear {
  clear: both;
}

.neos-nodetypes-textwithimage {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 20px;
  font-weight: 300;

  .neos-alignment {

    @include media-breakpoint-up(md) {
      &-left {
        padding: 0 20px 20px 0;
      }

      &-right {
        padding: 0 0 20px 20px;
      }
    }
  }

  figure {
    @include media-breakpoint-up(md) {
      //max-width: 40%;
      min-width: 500px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

}

.btn-addons-toggle {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.maparea {
  overflow: hidden;
}

.slick-git {
  max-width: 70%;
  margin: 0 auto;
}

.slick-counter {
  @include media-breakpoint-up(md) {
    //padding-top: 100px;
    //padding-bottom: 100px;
    overflow-x: hidden;
    .slick-list {
      overflow: visible;
    }
  }

}

.square-color {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  margin-right: 10px;
}

.slick {
  overflow-x: hidden;
}

form {
  legend {
    display: none;
  }
}

.jonnitto-multicolumn-2 {
  .col-sm-6 {
    //align-self: center;

    .neos-nodetypes-headline:not(.neos-nodetypes-headline--bar) {
      h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0 !important;
      }
    }
  }
}

.image-icon {
  text-align: center;

  img {
    max-width: 100px;
    margin: 0 auto;
  }
}

.cake {
  position: relative;

  .piece-of-cake {
    margin-top: -230px;
    position: relative;
    transition: ease-in 2s;
    overflow: hidden;

    &-content {
      opacity: 0;
      transition: ease 0.5s;
    }

    &-5 {
      z-index: 50;
    }

    &-4 {
      z-index: 60;
    }

    &-3 {
      z-index: 70;
    }

    &-2 {
      z-index: 80;
    }

    &-1 {
      z-index: 90;
    }

    &-0 {
      z-index: 100
    }
  }

  &:hover {
    .piece-of-cake {

      transition: ease-out 0.5s;
      margin-top: -140px;

      &-content {
        transition: ease 0.5s;
        opacity: 1;
      }
    }
  }
}

#paralax {
  //.neos-nodetypes-image {
  //  display: inline-block;
  //  img{
  //    max-width: 50%;
  //  }
  //}
}

.paralax {
  &-wrapper {

  }

  &-content {
    left: 0;
    right: 0;
    top: 30%;
  }
}

.map-container {
  @media print {
    margin-top: 20px;
  }

  & > div {
    min-height: 500px;
    @media print {
      min-height: 300px !important;
    }
  }
}
