.news {
  &__list {
    &-header {
      margin-top: 250px;
    }

    &-item {

    }

    &-sidebar {
      transition: ease 0.5s;
    }
  }

  &__header {
    padding-top: 250px;
  }

  &__content {

  }
}

.scroll {
  //.app-news-news, .app-news-newsfolder {
  .news {
    &__list {
      &-sidebar {
        top: 150px;
        transition: ease 0.5s;
      }

      //}
    }
  }
}
